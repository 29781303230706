@import-normalize; 

$break-large: 1000px;

*{
  -webkit-box-sizing:border-box;
  -moz-box-sizing:border-box;
  -ms-box-sizing:border-box;
  box-sizing:border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgb(42, 49, 53);
  font-size: 1.2rem;
}

.container--page {
  min-height: 100vh;
  display: grid;
  grid-template-rows: 1;
  align-content: center;
  @media screen and (min-width: $break-large) {
    justify-content: center;
    padding: 4rem;
  }
}

.container--list {
  /*
  box-shadow: 0 0 100px 0 rgba(0, 0, 0, 0.2),
    30px 30px 50px 0 rgba(0, 0, 0, 0.06);
  */
  border-radius: 5px;
  margin: 0;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  min-width: 60vw;
  min-height: 100vh;
  position: relative;
  padding-top: 1rem;
}
