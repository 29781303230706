.editor--container {
  min-height: 2.5rem;
}

.editor--textarea {
  border: none;
  margin: 0;
  margin-left: 6.3rem;
  margin-top:0.56rem;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.2rem;
  max-width: 100%;
  resize: none;
  background: transparent;
  color: rgb(75, 75, 75);
}

.editor--textarea:focus {
  outline: none;
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
}