%bullet {
  width: 1.6rem;
  height: 1.6rem;
  position: absolute;
}

.node-control-bullet {
  @extend %bullet;
  z-index: 2;
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition-duration: 300ms;
  opacity: 0.5;
  &:hover {
    transform: scale(1.2, 1.2) translate3d(0, 0, 0);
    opacity: 1
  }
}