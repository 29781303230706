$break-large: 1000px;

%verticle {
  justify-self: center;
  align-self: center;
  width: 1rem;
  height: 1rem;
  > svg {
    fill: #b3b3b3;
  }
  @media screen and (min-width: $break-large) {
    opacity: 0;
  }
}

.node--dnd--ref {
  width: 100%;
}

.node--layout--horizontal {
  display: flex;
  align-items: center;
}
.node--container {
  grid-area: node;
  position: relative;
  // Only do this on desktop
  &:hover {
    > .node > .node--show--children {
      opacity: 1;
    }
  }
}
.node {
  display: inline-grid;
  min-height: 3rem;
  grid-template-columns: 1rem 2rem 2rem 1fr 1rem;
  grid-template-areas: "leftverticle show-children control body rightverticle";
  @media screen and (min-width: $break-large) {
    grid-template-columns: 2rem 2rem 1fr;
    grid-template-areas: "show-children control body";
  }
  grid-template-rows: auto;
}

.node--hover--true {
  transform: scale3d(1.08, 1.08, 1);
  transition: transform 0.3s;
}
.node--show--children {
  width: 1rem;
  height: 1rem;
  @media screen and (min-width: $break-large) {
    opacity: 0;
  }
  grid-area: show-children;
  align-self: center;
  justify-self: center;
  z-index: 1;
}

.node--body {
  grid-area: body;
  justify-self: center;
  align-self: center;
  z-index: 1;
}
.node--input {
  border: none;
  margin: 0;
  margin-left: 0.5rem;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 1.2rem;
  resize: none;
  background: none;
  color: rgb(75, 75, 75);
  vertical-align: middle;
}
.node--input:focus {
  outline: none;
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
}

// Media query, if larger than max, don't show tab buttons.
.node--leftverticle {
  @extend %verticle;
  grid-area: leftverticle;
  @media screen and (min-width: $break-large) {
    position: absolute;
  }
}
.node--rightverticle {
  @extend %verticle;
  grid-area: rightverticle;
  @media screen and (min-width: $break-large) {
    position: absolute;
  }
}

.node--child {
  display: block;
}
.node--child--alignmentinidcator {
  border-left:transparent solid 1rem;
  @media screen and (min-width: $break-large) {
    border-left:transparent solid 2rem;
  }
}
.node--editor {
  display: block;
}

.node--control {
  height: 1.3rem;
  grid-area: control;
  align-self: center;
  z-index: 1;
  cursor: grab;
  &.node--control--isDragging--true {
    cursor: grabbing;
  }
  > div > svg#bullet {
    fill: black;
  }
}

.node--hoverindicator--true {
  display: grid;
  grid-template-columns: 20% 1fr;
  grid-template-areas: "left right";
  position: absolute;
  height: 100%;
  width: calc(100% - 2rem);
  background: #8080802b;
  border-radius: 2rem;
  margin-left: 2rem;
  transform: scale3d(1.08, 1.08, 1);
  transition: transform 0.3s, background 0.3s, color 0.3s;
  z-index: 0;
}
.node--hoverindicator--true > .node--hoverindicator--droplocation--left {
  background: #28de002e;
  grid-area: left;
  border-top-left-radius: 2rem;
  border-bottom-left-radius: 2rem;
}
.node--hoverindicator--true > .node--hoverindicator--droplocation--right {
  background: #0000ff0d;
  grid-area: right;
  border-top-right-radius: 2rem;
  border-bottom-right-radius: 2rem;
}
