.breadcrumb {
    padding-left: 3rem;
}
.breadcrumb--crumb {
  display: inline-block;
  cursor: pointer;
  &:hover {
    color: blue
  }
}

.breadcrumb--active {
  display: inline-block;
  font-weight: 700;
}